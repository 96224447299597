import { Injectable } from '@angular/core';
import { Permissions } from '../../../auth/enum/permissions.enum';

export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    permission: string[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    permission: string[];
    children?: ChildrenItems[];
}

const MENUITEMS = [
    {
        state: 'dashboard',
        name: 'dashboard.title',
        permission: [Permissions.NO_PERMISSION],
        type: 'link',
        icon: 'icon-speedometer icons',
    },
    {
        state: 'project',
        name: 'project.title',
        permission: [Permissions.READ_PROJECT],
        type: 'sub',
        icon: 'icon-note icons',
        children: [
            {
                state: 'projectInformation',
                name: 'project.overview.title',
                permission: [Permissions.READ_PROJECT],
            },
            {
                state: 'stepInformation',
                name: 'step.menuTitle',
                permission: [Permissions.READ_PROJECT],
            },
        ],
    },
    {
        state: 'nos',
        name: 'general.nos',
        permission: [Permissions.READ_NOS],
        type: 'sub',
        icon: 'icon-calendar icons',
        children: [
            {
                state: 'forecastPlanning',
                name: 'general.forecastPlanning',
                permission: [Permissions.READ_NOS],
            },
        ],
    },
    {
        state: 'shipping',
        name: 'general.shipping',
        permission: [Permissions.READ_SHIPPING],
        type: 'sub',
        icon: 'icon-plane icons',
        children: [
            {
                state: 'shippingDialog',
                name: 'general.shippingDialog',
                permission: [Permissions.READ_SHIPPING],
            },
            {
                state: 'container',
                name: 'menu.shipping.containerSearch',
                permission: [Permissions.READ_SHIPPING],
            },
            {
                state: 'containerInformation',
                name: 'menu.shipping.containerInformation',
                permission: [Permissions.READ_SHIPPING],
            },
            {
                // Nicht fertig gestellt glaube ich.
                // Verweis auf FASH-734
                state: 'containerArrivalsList',
                name: 'menu.shipping.containerArrivalsList',
                permission: [Permissions.READ_CONTAINER_ARRIVALS],
            },
        ],
    },
    {
        state: 'calculation',
        name: 'general.calculation',
        permission: [Permissions.READ_CALCUATION],
        type: 'sub',
        icon: 'icon-pie-chart',
        children: [
            {
                state: 'bomCalculation',
                name: 'menu.calculation.bomCalculation',
                permission: [Permissions.READ_CALCUATION],
            },
            {
                state: 'projectCalculation',
                name: 'menu.calculation.project',
                permission: [Permissions.READ_CALCUATION],
            },
        ],
    },
    {
        state: 'webshops',
        name: 'menu.webshops.title',
        permission: [Permissions.READ_SHOP],
        type: 'sub',
        icon: 'icon-basket',
        children: [
            {
                state: 'shopProducts',
                name: 'menu.webshops.shopproduct',
                permission: [Permissions.READ_SHOP],
            },
        ],
    },
    {
        state: 'reporting',
        name: 'reporting.menuTitle',
        permission: [Permissions.READ_REPORTING],
        type: 'sub',
        icon: 'icon-graph icons',
        children: [
            {
                state: 'sales',
                name: 'reporting.sales.menuTitle',
                permission: [Permissions.READ_REPORTING_SALES],
            },
            {
                state: 'konsi',
                name: 'reporting.konsi.menuTitle',
                permission: [Permissions.READ_REPORTING_SALES],
            },
        ],
    },
    {
        state: 'salesman',
        name: 'menu.salesman.title',
        permission: [Permissions.READ_SALESMAN],
        type: 'sub',
        icon: 'icon-organization icons',
        children: [
            {
                state: 'assignments',
                name: 'menu.salesman.assignments',
                permission: [Permissions.READ_SALESMAN],
            },
            {
                state: 'assignmentsWithCompare',
                name: 'menu.salesman.assignmentsWithCompare',
                permission: [Permissions.READ_SALESMAN],
            },
            {
                state: 'salesVolume',
                name: 'menu.salesman.salesVolume',
                permission: [Permissions.READ_SALESMAN],
            },
            {
                state: 'salesVloumeWithCompare',
                name: 'menu.salesman.salesVloumeWithCompare',
                permission: [Permissions.READ_SALESMAN],
            },
            {
                state: 'forecast',
                name: 'menu.salesman.forecast',
                permission: [Permissions.READ_SALESMAN],
            },
        ],
    },
    {
        state: 'workflow',
        name: 'workflow.title',
        permission: [Permissions.READ_WORKFLOW],
        type: 'sub',
        icon: 'icon-layers icons',
        children: [
            {
                state: 'workflowSteps',
                name: 'workflow.step.title',
                permission: [Permissions.READ_WORKFLOW],
            },
            {
                state: 'workflowSchemas',
                name: 'workflow.schema.title',
                permission: [Permissions.READ_WORKFLOW],
            },
        ],
    },
    {
        state: 'masterdata',
        name: 'menu.masterdata.title',
        permission: [Permissions.READ_MASTERDATA],
        type: 'sub',
        icon: 'icon-briefcase icons',
        children: [
            {
                state: 'company',
                name: 'company.title',
                permission: [Permissions.READ_MASTERDATA],
            },
        ],
    },
    {
        state: 'administration',
        name: 'administration.title',
        permission: [Permissions.READ_CONFIGURATION, Permissions.READ_ROLE_PERMISSION],
        type: 'sub',
        icon: 'icon-settings icons',
        children: [
            {
                state: 'configuration',
                name: 'configuration.title',
                permission: [Permissions.READ_CONFIGURATION],
            },
        ],
    },
    {
        state: 'user',
        name: 'user.title',
        permission: [Permissions.READ_USER, Permissions.READ_USERGROUP],
        type: 'sub',
        icon: 'icon-user icons',
        children: [
            {
                state: 'list',
                name: 'user.title',
                permission: [Permissions.READ_USER],
            },
            {
                state: 'groups',
                name: 'userGroup.title',
                permission: [Permissions.READ_USERGROUP],
            },
        ],
    },
];

@Injectable()
export class MenuItems {
    getAll(): Menu[] {
        return MENUITEMS;
    }
}
